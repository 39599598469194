@import "../../all-modules.global";

.language-dropdown {
  cursor: default;
  @include font-size(m);
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  &::before {
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 1px solid var(--icon);
    border-radius: 12px;
  }

  &_lang_ru:before {
    content: url('/static/img/flag/ru.svg');
  }

  &_lang_en:before {
    content: url('/static/img/flag/en.svg');
  }
}
