.header-link {
  text-decoration: none;
  cursor: pointer;
  display: block;
  position: relative;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 0;
  list-style: none;

  &__active {
    background: var(--icon);
  }

  a {
    padding: 12px;
    display: block;
  }

  a:hover, a {
    text-decoration: none;
  }
}
