@import "../../all-modules.global";

.header-user {
  @include font-size(m);

  &__avatar {
    cursor: pointer;
    width: 28px;
    height: 28px;
    margin-right: 8px;
    border-radius: 100%;
    border: 1px solid var(--icon);
  }

  &__balance {
    white-space: nowrap;
  }
}