@import "../../all-modules.global";

.header-mobile {
  display: flex;
  gap: 16px;

  &__menu {
    position: relative;
    z-index: 1;
    user-select: none;
  }

  .nav-link {
    color: var(--color);
  }

  &__toggle {
    display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    cursor: pointer;
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */
    -webkit-touch-callout: none;
  }

  &__icon {
    display: block;
    width: 30px;
    height: 2px;
    margin-bottom: 8px;
    position: relative;
    top: 4px;
    background: var(--color);
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    opacity 0.55s ease;
  }

  &__icon:first-child {
    transform-origin: 0 0;
  }

  &__icon:nth-last-child(2) {
    transform-origin: 0 100%;
  }

  &__toggle:checked ~ &__icon {
    opacity: 1;
    transform: rotate(45deg) translate(15px, -4px);
    background: var(--color);
    top: 0;
  }

  &__toggle:checked ~ &__icon:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  &__toggle:checked ~ &__icon:nth-last-child(2) {
    transform: rotate(-45deg) translate(6px, 14px)
  }

  &__toggle:checked ~ &__ul {
    transform: none;
  }

  &__ul {
    position: fixed;
    width: 300px;
    bottom: 0;
    padding: 82px 26px 42px;
    top: 0;
    right: 0;
    background: var(--card);
    transform: translateX(300px);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    gap: 2px;
  }

  &__copyright-text {
    margin: auto 0 0 10px;
    padding: 32px 0 0 0;
    font-size: 14px;
    line-height: 18px;
  }
}

@media (min-width: 901px) {
  .header-mobile {
    display: none;
  }
}
