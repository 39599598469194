.theme-toggle {
  display: flex;
  gap: 4px;
  align-items: center;

  input {
    display: none;
  }

  input:checked + label::before {
    background-color: var(--orange);
  }

  label {
    cursor: pointer;
    display: block;
    width: 24px;
    height: 24px;
  }

  label::before {
    width: 24px;
    height: 24px;
    content: '';
    background-color: var(--color);
    display: inline-block;
    mask-size: cover;
  }

  label:hover::before, label:checked::before {
    background-color: var(--orange);
  }

  &__moon::before {
    mask: url('/static/fonts/moon.svg') no-repeat 50% 50%;
  }

  &__sun::before {
    mask: url('/static/fonts/sun.svg') no-repeat 50% 50%;
  }
}
