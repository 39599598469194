@import "../../all-modules.global";

.right-menu {
  display: flex;
  align-items: center;
  gap: 16px;
  @include font-size(m);
  min-height: 48px;

  &__menu {
    display: flex;
    align-items: center;
  }

  &__dropdown {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 7px 8px 0 rgba(0, 0, 0, 0.12);
    background-color: var(--navbar);
    transform-origin: top left;
    position: absolute;
    width: 254px;
    padding: 0 16px;
    z-index: 10;
    border-radius: 0 0 4px 4px;
    top: $header-height;
    overflow: hidden;
    transition: max-height .4s ease, padding .4s ease;
    pointer-events: none;
    max-height: 0;
    @include font-size(sm);

    li {
      overflow: hidden;
      a {
        transition: .3s ease-in-out;
      }
    }
  }

  &__dropdown_active {
    padding: 16px;
    pointer-events: auto;
    max-height: 192px;
  }
}

@media (max-width: $header-menu-width) {
  .right-menu {
    display: none;
  }
}